<template>
  <picture v-if="imageName">
    <source type="image/webp" :srcset="`${pathToImage}.webp`" />
    <img
      class="image__provideServices"
      :class="{ image__singleService: singleService }"
      :src="`${pathToImage}.png`"
      :alt="`${service_slug} image`"
    />
  </picture>
</template>

<script>
export default {
  props: {
    imageName: String,
    singleService: Boolean,
    dragAndDropImage: Boolean,
    service_slug: String,
    size: String,
    folder: {
      default: "services",
      type: String,
    },
  },
  name: "ImageService",
  data() {
    return {
      path: process.env.VUE_APP_ORIGIN_UPLOADS_PATH,
    };
  },
  computed: {
    pathToImage() {
      return `${this.path}/${this.folder}/${this.service_slug}/${this.imageName}-${this.size}`;
    },
  },
};
</script>

<style></style>
