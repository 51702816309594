<template>
  <main-layout>
    <div class="d-flex align-items-center">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="m-0 ms-3">{{ serviceDescriptions.englishServiceName }}</h1>
    </div>
    <div class="servicesAddPage__steps w-100">
      <div
        @click="stepTwo = false"
        class="servicesAddPage__step servicesEditPage__step cursor-pointer"
        :class="{ 'servicesAddPage__step-active': !stepTwo }"
      >
        {{ $t("Description") }}
      </div>
      <div
        @click="stepTwo = true"
        class="servicesAddPage__step servicesEditPage__step cursor-pointer"
        :class="{ 'servicesAddPage__step-active': stepTwo }"
      >
        {{ $t("Thumbnail") }}
      </div>
    </div>
    <div class="servicesAddPage">
      <div v-if="!stepTwo">
        <div class="d-flex justify-content-center">
          <div
            class="expertAddPage__language"
            :class="{ 'expertAddPage__language-active': !language.english }"
            @click="languageChange('english')"
          >
            {{ $t("French") }}
          </div>
          <div
            class="expertAddPage__language"
            :class="{ 'expertAddPage__language-active': !language.french }"
            @click="languageChange('french')"
          >
            {{ $t("English") }}
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <base-input
            v-show="language.english"
            v-model="serviceDescriptions.englishServiceName"
            class="servicesAddPage__input"
            :error="v$.serviceDescriptions.englishServiceName.$error"
            :error-message="v$.serviceDescriptions.englishServiceName.$errors"
          >
            {{ $t("ServiceName") }}*
          </base-input>
          <base-input
            v-show="language.french"
            v-model="serviceDescriptions.frenchServiceName"
            class="servicesAddPage__input"
            :error="v$.serviceDescriptions.frenchServiceName.$error"
            :error-message="v$.serviceDescriptions.frenchServiceName.$errors"
          >
            {{ $t("ServiceName") }}*
          </base-input>
        </div>
        <div class="servicesAddPage__titleContainer">
          <div class="servicesAddPage__title">{{ $t("shortDescription") }}</div>
        </div>
        <div class="servicesAddPage__shortDescription">
          <div v-show="language.english">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="serviceDescriptions.shortDescriptionValue.english"
            ></ckeditor>
          </div>
          <div v-show="language.french">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="serviceDescriptions.shortDescriptionValue.french"
            ></ckeditor>
          </div>
        </div>
        <div class="servicesAddPage__titleContainer">
          <div class="servicesAddPage__title">{{ $t("fullDescription") }}</div>
        </div>
        <div class="servicesAddPage__fullDescription">
          <div v-show="language.english">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="serviceDescriptions.fullDescriptionValue.english"
            ></ckeditor>
          </div>
          <div v-show="language.french">
            <ckeditor
              :editor="editor"
              :config="editorConfig"
              v-model="serviceDescriptions.fullDescriptionValue.french"
            ></ckeditor>
          </div>
        </div>
      </div>
      <div v-show="stepTwo" class="position-relative">
        <div v-if="!!!thumbnail" class="servicesAddPage__oldImage-wrapper">
          <image-service
            v-if="oldImage"
            class="servicesAddPage__oldImage"
            size="500"
            :single-service="true"
            :service_slug="service_slug"
            :image-name="oldImage"
          />
        </div>
        <drag-and-drop
          ref="thumbnail"
          :have-old-image="oldImage"
          v-model="thumbnail"
        />
      </div>
      <div
        class="servicesEditPage__footer"
        :class="{
          'justify-content-between': stepTwo && (oldImage || thumbnail),
        }"
      >
        <base-button
          v-if="stepTwo && (oldImage || thumbnail)"
          class="popup__remove-logo-btn"
          @click="removeImage()"
        >
          {{ $t("RemoveThumbnail") }}
        </base-button>
        <div class="d-flex align-items-center">
          <div
            v-show="!haveChanges || !!thumbnail"
            @click="getServiceInformation()"
            class="servicesAddPage__cancel"
          >
            {{ $t("discardChanges") }}
          </div>
          <base-button :is-loaded="imageIsLoad" @click="editServices()">
            {{ $t("accountOverviewPageSave") }}
          </base-button>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseInput from "@/components/UI/inputs/BaseInput";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import BaseButton from "@/components/UI/buttons/BaseButton";
import DragAndDrop from "@/components/elements/DragAndDrop";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import ImageService from "@/components/UI/images/ImageService";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "ServicesEditPage",
  components: {
    ImageService,
    HorizontalArrow,
    DragAndDrop,
    BaseButton,
    BaseInput,
    MainLayout,
  },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      removeLogoState: false,
      informationBackups: {},
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "undo",
            "redo",
          ],
        },
      },
      haveChanges: true,
      stepTwo: false,
      oldImage: null,
      service_slug: null,
      service_id: null,
      thumbnail: "",
      language: {
        english: false,
        french: true,
      },
      serviceDescriptions: {
        shortDescriptionValue: {
          english: "",
          french: "",
        },
        fullDescriptionValue: {
          english: "",
          french: "",
        },
        englishServiceName: "",
        frenchServiceName: "",
      },
    };
  },
  validations() {
    return {
      serviceDescriptions: {
        englishServiceName: { required },
        frenchServiceName: { required },
      },
    };
  },
  mounted() {
    this.getServiceInformation();
    this.$store.commit("SET_LOADED_STATE", false);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.haveChanges) {
      let changesPopup = {
        show: true,
        to: to.path,
        save: null,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    } else {
      return next();
    }
  },
  methods: {
    getServiceInformation() {
      this.$http
        .get("/services/slug/" + this.$route.params.service)
        .then(({ data }) => {
          let info = {
            user_id: data.body.id,
            serviceName: data.body.name,
            role_id: 5,
            previewPath: "/service/" + this.$route.params.service,
          };
          this.$store.commit("SET_EDIT_USER_INFORMATION", info);
          this.$http.get("/services/" + data.body.id, {}).then(({ data }) => {
            this.serviceDescriptions.englishServiceName =
              data.body.translations[0].name;
            this.serviceDescriptions.frenchServiceName =
              data.body.translations[1].name;
            this.serviceDescriptions.shortDescriptionValue.english = data.body
              .translations[0].short_description
              ? data.body.translations[0].short_description
              : "";
            this.serviceDescriptions.fullDescriptionValue.english = data.body
              .translations[0].description
              ? data.body.translations[0].description
              : "";
            this.serviceDescriptions.shortDescriptionValue.french = data.body
              .translations[1].short_description
              ? data.body.translations[1].short_description
              : "";
            this.serviceDescriptions.fullDescriptionValue.french = data.body
              .translations[1].description
              ? data.body.translations[1].description
              : "";
            this.service_id = data.body.id;
            this.oldImage = data.body.image;
            this.service_slug = data.body.slug;
            this.informationBackups = JSON.parse(
              JSON.stringify(this.serviceDescriptions)
            );
          });
        });
    },
    discardImage() {
      this.$refs.thumbnail.remove();
      this.thumbnail = null;
    },
    languageChange(language) {
      for (let i in this.language) {
        this.language[i] = i !== language;
      }
    },
    removeImage() {
      this.thumbnail = null;
      this.$refs.thumbnail.remove();
      if (this.oldImage) {
        this.oldImage = null;
        this.removeLogoState = true;
        this.editServices();
      }
    },
    editServices() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      let shortDescriptionValue = {};
      let fullDescriptionValue = {};
      shortDescriptionValue.english = this.serviceDescriptions
        .shortDescriptionValue.english
        ? this.serviceDescriptions.shortDescriptionValue.english
        : null;
      shortDescriptionValue.french = this.serviceDescriptions
        .shortDescriptionValue.french
        ? this.serviceDescriptions.shortDescriptionValue.french
        : null;
      fullDescriptionValue.french = this.serviceDescriptions
        .fullDescriptionValue.french
        ? this.serviceDescriptions.fullDescriptionValue.french
        : null;
      fullDescriptionValue.english = this.serviceDescriptions
        .fullDescriptionValue.english
        ? this.serviceDescriptions.fullDescriptionValue.english
        : null;
      if (!this.imageIsLoad) {
        let image =
          this.thumbnail === null || this.thumbnail.length > 0
            ? this.thumbnail
            : this.oldImage;
        this.$store.commit("SET_LOADED_STATE", true);
        this.$http
          .patch("/services/" + this.service_id, {
            image: image,
            translations: [
              {
                language_id: 1,
                name: this.serviceDescriptions.englishServiceName,
                short_description: shortDescriptionValue.english,
                description: fullDescriptionValue.english,
              },
              {
                language_id: 2,
                name: this.serviceDescriptions.frenchServiceName,
                short_description: shortDescriptionValue.french,
                description: fullDescriptionValue.french,
              },
            ],
          })
          .then(() => {
            this.haveChanges = true;
            this.toast.success(this.$t("successfulSaveData"));
            this.$store.commit("SET_LOADED_STATE", false);
            if (this.removeLogoState) {
              this.removeLogoState = false;
              return;
            }
            this.$router.push("/services");
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(err.response.data.message);
            } else {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
  },
  computed: {
    popupsInformation() {
      return this.$store.getters.STATE_POPUP;
    },
    ...mapGetters({
      imageIsLoad: "LOADED_STATE",
    }),
  },
  watch: {
    serviceDescriptions: {
      deep: true,
      handler(newCount) {
        this.haveChanges =
          JSON.stringify(this.informationBackups) === JSON.stringify(newCount);
      },
    },
    popupsInformation: {
      deep: true,
      handler(newCount) {
        if (newCount.save) {
          this.haveChanges = true;
          this.editServices();
        }
        if (newCount.leave) {
          this.haveChanges = true;
          this.$router.push(newCount.to);
        }
      },
    },
  },
};
</script>

<style></style>
